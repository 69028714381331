import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ADMIN_MODAL_CONVERSATION_CREATE } from 'Consts/routes';

import { CONVERSATION_ROLES } from 'Consts/chat';

import { parseToQueryString, parseQueryToObject } from 'Utils/querystring';
import { filterKeys } from 'Utils/object';

import StyledComponent from 'Components/core/StyledComponent';
import ConversationsList from 'Components/admin/chat/ChatConversationsList';
import ConversationWindow from 'Components/admin/chat/ChatConversationWindow';

export default class AdminChat extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            listConversations: PropTypes.func.isRequired,
            listAllConversations: PropTypes.func.isRequired,
            listConversationMessages: PropTypes.func.isRequired,
            sendConversationMessages: PropTypes.func.isRequired,
            enableConversation: PropTypes.func.isRequired,
            disableConversation: PropTypes.func.isRequired,
        }).isRequired,
        conversationsList: PropTypes.shape({
            isLoaded: PropTypes.bool.isRequired,
            elements: PropTypes.array.isRequired,
        }),
    };
    static defaultProps = {
        conversationsList: null,
    };
    state = {
        isMessagesLoading: false,
        messages: [],
        perPage: 100,
    };
    loadMessagesInteval = 0;

    defaultQuery = {
        conversationId: null,
        query: '',
        conversationRole: '',
        enabled: 'true',
        active: 'true',
    };

    getQueryConfig = (props = this.props) => {
        const { location } = props;
        const queryObject = parseQueryToObject(location.search);

        return filterKeys(
            this.defaultQuery,
            queryObject,
            Object.keys(this.defaultQuery)
        );
    }

    componentDidMount = () => {
        this.loadConversations();

        this.loadMessagesInteval = setInterval(() => {
            this.loadMessages();
        }, 2500);
    }

    componentWillUnmount = () => {
        clearInterval(this.loadMessagesInteval);
    }

    componentDidUpdate = prevProps => {
        const { conversationsList, history, location } = this.props;
        const previousQueryObject = this.getQueryConfig(prevProps);
        const queryObject = this.getQueryConfig();

        if (conversationsList.isLoaded && !queryObject.conversationId) {
            const firstConveration = conversationsList.elements[0] && conversationsList.elements[0] || null;
            if (firstConveration) {
                history.push(parseToQueryString(location.pathname, { ...queryObject, conversationId: firstConveration.id }));
            }
        }

        if (previousQueryObject.conversationId !== queryObject.conversationId) {
            this.setState({ isMessagesLoading: true });
            this.loadMessages()
                .then(() => this.setState({ isMessagesLoading: false }));
        }

        if (JSON.stringify(previousQueryObject) !== JSON.stringify(queryObject)) {
            this.loadConversations();
        }
    }

    loadConversations = () => {
        const { actions } = this.props;
        const queryObject = this.getQueryConfig();

        actions.listConversations({
            page: 1,
            perPage: 100,
            conversationRole: queryObject.conversationRole,
            search: queryObject.query,
            enabled: queryObject.enabled === 'true'
                ? true
                : queryObject.enabled === 'false'
                    ? false
                    : undefined,
            active: queryObject.active === 'true'
                ? true
                : queryObject.active === 'false'
                    ? false
                    : undefined,
        });
    }

    loadMessages = () => {
        const { actions } = this.props;
        const { perPage } = this.state;
        const queryObject = this.getQueryConfig();

        return actions.listConversationMessages({
            conversationId: queryObject.conversationId,
            perPage,
            ignoreStore: true,
        })
            .then(response => {
                this.setState({ messages: response.chatMessages.elements });
            });
    }

    loadEarlierMessages = () => {
        this.setState(prevState => ({ perPage: prevState.perPage + 100 }), () => {
            this.loadMessages();
        });
    }

    onSend = (messages = []) => {
        const { actions } = this.props;
        const queryObject = this.getQueryConfig();

        return actions.sendConversationMessages({ conversationId: queryObject.conversationId, messages });
    }

    render() {
        const { conversationsList, location, history, actions } = this.props;
        const { messages, isMessagesLoading } = this.state;
        const queryObject = this.getQueryConfig();
        const currentConversation = conversationsList && conversationsList.elements
            .find(element => element.id === queryObject.conversationId);

        return (
            <StyledComponent className="page-chat" styles={require('./styles')}>
                <div className="chat-container">
                    <div className="conversations-list-container">
                        <div className="controls">
                            <a
                                className="control"
                                onClick={() => history.push(parseToQueryString(location.pathname, queryObject) + ADMIN_MODAL_CONVERSATION_CREATE)}
                            >
                                Nowy kanał <i className="fa fa-plus" />
                            </a>
                        </div>
                        <ConversationsList
                            conversationsList={conversationsList}
                            currentConversationId={queryObject.conversationId}
                            onSelectConversation={nextConversation => history.push(parseToQueryString(location.pathname, { ...queryObject, conversationId: nextConversation.id }))}
                            searchable={{
                                visible: true,
                                onChange: query => history.push(parseToQueryString(location.pathname, { ...queryObject, query })),
                                query: queryObject.query || this.defaultQuery.query,
                            }}
                            filters={{
                                visible: true,
                                onChange: filter => history.push(parseToQueryString(location.pathname, { ...queryObject, [filter.name]: filter.value })),
                                elements: [{
                                    visible: true,
                                    id: 'active',
                                    label: 'Aktywne',
                                    type: 'select',
                                    value: queryObject.active,
                                    options: [{
                                        label: 'Wszystkie',
                                        value: '',
                                    }, {
                                        label: 'Tylko aktywne',
                                        value: 'true',
                                    }, {
                                        label: 'Tylko nieaktywne',
                                        value: 'false',
                                    }],
                                }, {
                                    visible: true,
                                    id: 'conversationRole',
                                    label: 'Typ kanału',
                                    type: 'select',
                                    value: queryObject.conversationRole,
                                    options: [{
                                        label: 'Wszystkie',
                                        value: '',
                                    },
                                    ...CONVERSATION_ROLES.map(option => ({
                                        ...option, value: option.key
                                    }))]
                                }],
                            }}
                            onMapElementControls={element => ([
                                {
                                    visible: element.enabled,
                                    type: 'button',
                                    label: 'Wyłącz',
                                    onClick: () => actions.disableConversation(element),
                                }, {
                                    visible: !element.enabled,
                                    type: 'button',
                                    label: 'Włącz',
                                    onClick: () => actions.enableConversation(element),
                                },
                            ])}
                        />
                    </div>
                    <div className="chat-window-container">
                        {currentConversation &&
                            <ConversationWindow
                                conversation={currentConversation}
                                messages={messages || []}
                                isLoading={isMessagesLoading}
                                onSend={this.onSend}
                                onLoadEarlierMessages={this.loadEarlierMessages}
                            />
                        }
                    </div>
                </div>
            </StyledComponent>
        );
    }
}
