import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) =>
    css({
        '.tabs-wrapper': {
            width: '100%',
            display: 'flex',
            justifyContent: 'ceter',
            alignItems: 'center',

            '.tab': {
                width: '100%',
                textAlign: 'center',
                cursor: 'pointer',
                padding: '2em 0',
                borderRight: '1px solid #CCCCCC',

                '&.active': {
                    fontWeight: '600',
                    background: 'white',
                },

                '&:last-of-type': {
                    borderRight: 'none',
                },
            },
        },
        '.searchable-wrapper': {
            width: '100%',
            display: 'flex',
            justifyContent: 'ceter',
            alignItems: 'center',

            '.searchable-input': {
                width: '100%',
                height: '2em',
                padding: '0 1em',
                border: '1px solid #EEE',
                color: '#666666',
                fontSize: '1.2em',
            },
        },
        '.filters-wrapper': {
            background: '#EEEEEE',
            padding: '1em',
            borderBottom: '1px solid #CCCCCC',

            '.filters-controls': {
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',

                '.controls-title': {
                    fontSize: '1em',
                    color: '#666666',
                },
                '.controls-cta': {
                    fontSize: '1em',
                    fontWeight: '600',
                    cursor: 'pointer',
                    color: '#0981f8',
                },
            },
            '.filters-list': {
                marginTop: '.5em',

                '.filter-wrapper': {
                    '.filter': {
                        '&.type-select': {
                            '.form-select-component': {
                                '.input-label': {
                                    fontSize: '.9em',
                                },
                                select: {
                                    height: '3em',
                                },
                            },
                        },
                    },
                },
            },
        },
        '.conversations-list-content': {
            maxHeight: '81vh',
            overflow: 'auto',

            '.list-label': {
                padding: '1em',
                color: '#CCCCCC',
            },
            '.list-text-filter': {
                padding: '1em',
                cursor: 'pointer',
                color: '#0981f8',
                textDecoration: 'underline',
                display: 'block',
            },
        },
    });

