import React, { Component } from 'react';
import PropTypes from 'prop-types';

import StyledComponent from 'Components/core/StyledComponent';
import Spinner from 'Components/layoutAdmin/Spinner';
import NoResult from 'Components/layoutAdmin/NoResult';
import Switch from 'Components/forms/Switch';
import Select from 'Components/forms/Select';
import ConverationsListItem from 'Components/admin/chat/ChatConversationListItem';

export default class ChatConverationsList extends Component {
    static propTypes = {
        currentConversationId: PropTypes.string,
        onSelectConversation: PropTypes.func.isRequired,
        conversationsList: PropTypes.shape({
            isLoaded: PropTypes.bool.isRequired,
            elements: PropTypes.array.isRequired,
        }),
        tabs: PropTypes.shape({
            visible: PropTypes.bool,
            onChange: PropTypes.func.isRequired,
            currentTabId: PropTypes.string.isRequired,
            elements: PropTypes.arrayOf(
                PropTypes.shape({
                    id: PropTypes.string.isRequired,
                    label: PropTypes.string.isRequired,
                })
            ).isRequired,
        }),
        searchable: PropTypes.shape({
            visible: PropTypes.bool,
            onChange: PropTypes.func.isRequired,
            query: PropTypes.string,
        }),
        filters: PropTypes.shape({
            visible: PropTypes.bool,
            onChange: PropTypes.func.isRequired,
            elements: PropTypes.arrayOf(
                PropTypes.shape({
                    id: PropTypes.string.isRequired,
                    label: PropTypes.string,
                    type: PropTypes.oneOf([
                        'switch',
                        'select',
                    ]).isRequired,
                    value: PropTypes.oneOfType([
                        PropTypes.string,
                        PropTypes.number,
                        PropTypes.bool,
                    ]),
                    options: PropTypes.arrayOf(
                        PropTypes.shape({
                            label: PropTypes.string,
                            value: PropTypes.string,
                        })
                    ),
                })
            ).isRequired,
        }),
        onMapElementControls: PropTypes.func,
    };
    static defaultProps = {
        currentConversationId: null,
        conversationsList: null,
        onMapElementControls: null,
    };
    state = {
        filtersExpanded: false,
    };

    getFilter = ({ type, id, label, value, options }) => {
        const { filters } = this.props;
        const { onChange } = filters;

        switch (type) {
            case 'switch':
                return (
                    <div className="filter type-switch">
                        <Switch
                            label={label}
                            name={id}
                            onChange={onChange}
                            value={value}
                        />
                    </div>
                );
            case 'select':
                return (
                    <div className="filter type-select">
                        <Select
                            label={label}
                            name={id}
                            onChange={onChange}
                            value={value}
                            options={options}
                        />
                    </div>
                );
        }
    }

    render() {
        const { conversationsList, currentConversationId, onSelectConversation, tabs, searchable, filters, onMapElementControls } = this.props;
        const { filtersExpanded } = this.state;

        return (
            <StyledComponent className="conversations-list" styles={require('./styles')}>
                {tabs && tabs.visible &&
                    <div className="tabs-wrapper">
                        {tabs.elements.map(element => (
                            <a
                                key={element.id}
                                className={`tab ${tabs.currentTabId === element.id ? 'active' : ''}`}
                                onClick={() => tabs.onChange(element.id)}
                            >
                                {element.label}
                            </a>
                        ))}
                    </div>
                }
                {searchable && searchable.visible &&
                    <div className="searchable-wrapper">
                        <input
                            type="text"
                            className="searchable-input"
                            value={searchable.query}
                            onChange={e => searchable.onChange(e.target.value)}
                            placeholder="Wyszukaj..."
                        />
                    </div>
                }
                {filters && filters.visible &&
                    <div className="filters-wrapper">
                        <div className="filters-controls">
                            <span className="controls-title">
                                Filtry
                            </span>
                            <a
                                className="controls-cta"
                                onClick={() => this.setState(prevState => ({ filtersExpanded: !prevState.filtersExpanded }))}
                            >
                                {filtersExpanded ? 'Zwiń' : 'Rozwiń'}
                            </a>
                        </div>
                        {filtersExpanded &&
                            <div className="filters-list">
                                {filters.elements
                                    .filter(element => element.visible)
                                    .map(filter => (
                                        <div className="filter-wrapper" key={filter.id}>
                                            {this.getFilter(filter)}
                                        </div>
                                    ))
                                }
                            </div>
                        }
                    </div>
                }
                <div className="conversations-list-content">
                    <p className="list-label">Ostatnie 100 kanałów wg. ostatniej wiadomości</p>
                    {!conversationsList || conversationsList.isLoading && <Spinner />}
                    {conversationsList && conversationsList.elements.length === 0 &&
                        <NoResult />
                    }
                    {conversationsList
                        .elements
                        .map(conversation => (
                            <ConverationsListItem
                                key={conversation.id}
                                conversation={conversation}
                                isActive={conversation.id === currentConversationId}
                                onSelectConversation={onSelectConversation}
                                onMapElementControls={onMapElementControls}
                            />
                        ))}
                </div>
            </StyledComponent>
        );
    }
}
